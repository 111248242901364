<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover">
        <div class="page-inner" id="main_page">
          <div class="va-card">
            <div class="dash_card_body">
              <div class="header_section">
                <h4 class="menu_header">Offer Report Details</h4>
                <div class="" v-if="adminRoles.includes(user.role.id)">
                  <div class="btn-toolbar">
                    <button class="btn btn-default custom_btn" @click="exportOffers(report)">
                      <span v-if="exporting"><i class="fa fa-spinner fa-spin"></i></span> <span v-else><i class="fas fa-file-export"></i> </span> Excel
                    </button>
                  </div>
                </div>
              </div>
  
              <div class="search_section">
                <div class="row">
                  <div class="col-md-10 col-sm-12">
                    <div class="form-group mt-1">
                      <select v-model="report" @change="filterReport()" class="form-control">
                        <option value="">--Select Offer Report --</option>
                        <option value="offers_by_product">Offers By Product</option>
                        <option value="offers_by_channel">Offers By Channel</option>
                        <option value="offers_by_store">Offers By Store</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <button class="btn btn_reserveport_orange mt-1" @click="filterReport()"><span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span> search</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <Datepicker placeholder="Start Date" v-model="startDate" utc autoApply :format="customFormatter"></Datepicker>
                  </div>
                  <div class="col-md-2">
                    <Datepicker placeholder="End Date" v-model="endDate" utc autoApply :format="customFormatter"></Datepicker>
                  </div>
                  <div class="col-md-2" v-if="report !='topcustomers'">
                    <select class="form-control" v-model="searchChannel" >
                      <option value="">--Select Channel--</option>
                      <option value="FOODIE">FOODIE</option>
                      <option v-for="channel in channels" :key="channel.id" :value="channel.channel_name">
                        {{channel.channel_name}}</option>
                    </select>
                  </div>
                  <div class="col-md-2" v-if="report !='merchants'">
                    <Multiselect v-model="location" :close-on-select="true" :options="strsoptions" :custom-label="name" :searchable="true" :placeholder="'Select  Store'" label="name" track-by="name"></Multiselect>
                  </div>
            
                </div>
              </div>
  
            </div>
            <div class="dash_card_body">
              <div class="summary_body">
                <!-- orders Report -->
                <div class="row" v-if="report !='offers'">
                  <div class="col-md-12">
                    <div class="cardbox card h-100">
                      <h4>{{rptitle}} </h4>
                      <div class="">
                        <div v-if="report =='offers_by_product'">
                          <ProductOfferTable 
                            v-bind:productOffersSales="offersDetails"
                            v-bind:loading="loading"
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></ProductOfferTable>
                          <!-- <product-table/> -->
                        </div>
                        <div v-else-if="report =='offers_by_store'">
                          <StoreOfferTable 
                            v-bind:storeoffersSales="offersDetails"
                            v-bind:loading="loading"
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></StoreOfferTable>
                        </div>
                        <div v-else-if="report =='offers_by_channel'">
                          <ChannelOfferTable 
                            v-bind:channelOffersSales="offersDetails"
                            v-bind:loading="loading"
                            v-bind:currency="pesapalConfig.currency_code"
                            v-bind:totalPages="totalPages"
                            v-bind:page="page"
                            v-bind:dataperPage="dataperPage"
                            @record-changed="onChangeRecordsPerPage"
                            @page-changed="onPageChange"
                          ></ChannelOfferTable>
                        </div>                       
                      </div>
                      
                  
                    </div>
                  </div>
  
                </div>
        
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>
  
  <script>
  import moment from 'moment';
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  import ProductOfferTable from '@/pages/admin/dashboard/reportDetails/offerDetails/productsOfferTable.vue'
  import StoreOfferTable from '@/pages/admin/dashboard/reportDetails/offerDetails/storeOfferTable.vue' 
  import ChannelOfferTable from '@/pages/admin/dashboard/reportDetails/offerDetails/channelsOfferTable.vue'  

  import Multiselect from '@vueform/multiselect';
  
  export default {
      components: {
          Multiselect,
          ProductOfferTable,
          StoreOfferTable,
          ChannelOfferTable,
         
      },
      data() {
          return {
            offersDetails:[],
            report: this.$route.params.type,
            page:1,
            totalPages: 0,
            totalRecords: 0,
            dataperPage: 10,
            loading:false,
            startDate: this.$store.state.ordReportstartDate,
            endDate: this.$store.state.ordReportendDate,
            location: this.$store.state.ordReportlocation,
            searchChannel:'',
            stores:[],
            channels:[],
            strsoptions:[],
            exporting:false
          }
      },
      mounted: async function () {
          this.getBranches();
          this.getOffersData();
          this.getChannels();
      },
      methods: {
       
          filterReport() {
              if (this.report != '') {
                this.$router.push({
                    name: 'offer_details',
                    params: {
                        type: this.report
                    }
                });
  
              } 
              this.getOffersData();
          },
          customFormatter(date) {
              return moment(date).format('YYYY-MM-DD');
          },
          async exportOffers(reportType){
            this.exporting = true
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              let url = `${this.dashBaseUrl}/offers_summary/${this.merchantID}`;
              url = new URL(url);
              if (this.startDate !== '') {
                  url.searchParams.set('start_date', this.startDate);
              }
              if (this.endDate !== '') {
                  url.searchParams.set('end_date', this.endDate);
              }
              if (this.location !== '') {
                  url.searchParams.set('store', this.location);
              }
              if(this.searchChannel !==''){
                url.searchParams.set('channel',this.searchChannel);
              }
              try{
                  const response = await this.axios.get(url,config)
                  console.log('response data opffer', response.data.data)
                  if(response.status === 200){
                    this.exporting = false
                    const productSales = response.data.data.product_sales
                    const storeSales = response.data.data.store_sales
                    const summarysales =  response.data.data.summarysales
                    const channelsales = response.data.data.channel_sales
                   
                    const offerSummary = [];
                    for (const summary of Object.values(summarysales)) {
                    offerSummary.push({
                            'Offer Name':summary.offer_name,
                            'Orders':summary.total_order,
                            'Sales':this.formatPrice(summary.total_amount)
                        })
                    }
                    const fileName = Date.now() + '_offer_summary'
                    this.exportExcel(offerSummary, fileName)
                      
                    if(reportType == 'offers_by_store'){
                      const storeSaleSummary = []
                      for(const storeSale of Object.values(storeSales)){
                        storeSaleSummary.push({
                          'Store':storeSale.name,
                          'Orders':storeSale.total_order,
                          'Sales':storeSale.total_amount
                        })
                      }
                      const fileName = Date.now() + 'offer_per_store' 
                      this.exportExcel(storeSaleSummary,fileName)
                    }else if(reportType == 'offers_by_product'){
                      const productSaleSummary = []
                      for(const productsale of Object.values(productSales)){
                        productSaleSummary.push({
                          'Name':productsale.name,
                          'Offer Name':productsale.offer_name,
                          'Orders':productsale.total_order,
                          'Sales':productsale.total_amount,
                          'Items sold':productsale.total_qty,
                          'Item sales':productsale.item_total
                        })
                      }
                      const fileName = Date.now() + 'offer_per_product' 
                      this.exportExcel(productSaleSummary,fileName)
                    }else { 
                      const channelOfferSummary = []
                      for(const channelSale of Object.values(channelsales)){
                        channelOfferSummary.push({
                          'Channel':channelSale.name,
                          'Offer Name': channelSale.offer_name,
                          'Orders': channelSale.total_order,
                          'Sales':channelSale.total_amount
                        })
  
                      }
                      const fileName = Date.now() + '_offers_per_channel'
                      this.exportExcel(channelOfferSummary,fileName)
                    }
                 }
              }catch(err){
                  // 
                  this.exporting = false
              }
           
  
        },
          async getOffersData(){
              this.loading = true
              const LoaderCover = document.getElementById('main-loading-cover');
                LoaderCover.style.opacity = .4;
                const loader = document.getElementById('main-loader');
                loader.style.display = 'block';
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              let url = `${this.dashBaseUrl}/offers_summary/${this.merchantID}`;
              url = new URL(url);
              url.searchParams.set('page', this.page);
              url.searchParams.set('size', this.dataperPage);
              if (this.startDate !== '') {
                  url.searchParams.set('start_date', this.startDate);
              }
              if (this.endDate !== '') {
                  url.searchParams.set('end_date', this.endDate);
              }
              if (this.location !== '' && this.location !=undefined) {
                  url.searchParams.set('store', this.location);
              }
              if(this.searchChannel !==''){
                url.searchParams.set('channel',this.searchChannel);
              }
              try{
                  const response = await this.axios.get(url,config)
                  if(response.status === 200){
                      this.loading = false
                      LoaderCover.style.opacity = 1;
                        loader.style.display = 'none';
                      switch(this.report){
                        case "offers_by_product":
                            this.offersDetails = response.data.data.product_sales.data;
                            this.totalPages =  Math.ceil(response.data.data.product_sales.total / this.dataperPage);
                            break;
                        case "offers_by_channel":
                            this.offersDetails = response.data.data.channel_sales.data
                            this.totalPages =  Math.ceil(response.data.data.channel_sales.total / this.dataperPage);
                            break;
                        default:
                            this.offersDetails = response.data.data.store_sales.data;
                            this.totalPages =  Math.ceil(response.data.data.store_sales.total / this.dataperPage);
                      }
                     

                    
                 }
              }catch(err){
                  // 
                  this.loading = false
                  LoaderCover.style.opacity = 1;
                    loader.style.display = 'none';
              }
          },
          async getBranches() {
              const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
              this.axios.get(url, this.apiRequestConfig()).then((response) => {
                  if (response.status === 200) {
                      if (response.data.data !== null) {
                          const stores = response.data.data;
                          this.stores = stores;
                          for (let i = 0; i < stores.length; i++) {
                              this.strsoptions.push({
                                  "value": stores[i].id,
                                  "name": stores[i].branch_name
                              })
                          }
                      }
                  }
              }).catch((err) => {
                  console.log('error', err);
              })
          },
          async getChannels() {
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
              this.axios.get(url, config).then((response) => {
                  if (response.status === 200) {
                      this.channels = response.data.data
                  }
              });
          },
          onPageChange(page) {
              this.page = page
              this.getOffersData();
          },

          onChangeRecordsPerPage(recordSize) {
            this.dataperPage = recordSize
            this.getOffersData();
          },
  
      },
      mixins: [ReusableDataMixins, ReusableFunctionMixins]
  }
  </script>
  