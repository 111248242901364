<template>
  <div class="table_section">
    <table class="table  table-hover">
      <thead>
        <th width="40%">Channel Name</th>
        <th>Offer Name</th>
        <th>Order</th>
        <th>Sales (<b>{{ currency }}</b>)</th>                  
      </thead>
      <tbody v-if="channelOffersSales.length > 0">
        <tr v-for="channelSale in channelOffersSales" :key="channelSale.name">
          <td>
            <span class="title_text">
              {{channelSale.name}}
            </span>
  
          </td>
          <td>{{channelSale.offer_name}}</td>
          <td>{{channelSale.total_order}}</td>
          <td>{{formatPrice(channelSale.total_amount)}}</td>
                            
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4" style="text-align:center;">No data</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4">
            <div id="pagination" v-if="totalPages > 1">
              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="pageChange" />
              <ul class="showItems">
                <li>Show Items:
                  <select @change="recordChange" v-model="recordsPerPage">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="30">30</option>
                    <option :value="40">40</option>
                    <option :value="50">50</option>
                  </select>
                </li>
  
              </ul>
            </div>
          </td>
        </tr>
          
      </tfoot>
    </table>
  </div>
</template>
    <script>
    import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
    import Pagination from '@/pages/pagination/pagination.vue';
    export default {
        props: ['channelOffersSales','loading',
                'currency','totalPages', 'page','dataperPage'],
      
        mixins: [ReusableFunctionMixins],
        components: {
          Pagination,
        },
        data() {
              return {
                  recordsPerPage:10
              }
          },
          mounted:async function (){
              this.recordsPerPage = this.dataperPage
          },
          methods: {
              recordChange(){
                  this.$emit('record-changed', this.recordsPerPage)
              },
              pageChange(page){
                  this.$emit('page-changed', page)
              }
              
          },
    }
    </script>