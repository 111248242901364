<template>
  <div class="table_section">
    <table class="table  table-hover">
      <thead>
        <th width="25%" >Product</th>
        <th width="25%">Offer Name</th>
        <th>Orders</th>
        <th>Order Sales</th>
        <th>Items Sold</th>
        <th>Item Sales</th>                   
      </thead>
      <tbody v-if="productOffersSales.length > 0">
        <tr v-for="productSale in productOffersSales" :key="productSale.name">
          <td>
            <span class="title_text">
              {{productSale.name}}
            </span>
  
          </td>
          <td>
            {{ productSale.offer_name }}
          </td>
          <td>{{productSale.total_order}}</td>
          <td>{{formatPrice(productSale.total_amount)}}</td>
          <td>{{ productSale.total_qty }}</td>
          <td>{{ formatPrice(productSale.item_total) }}</td>
                                 
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6" style="text-align:center;">No data</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="6">
            <div id="pagination" v-if="totalPages > 1">
              <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="pageChange" />
              <ul class="showItems">
                <li>Show Items:
                  <select @change="recordChange" v-model="recordsPerPage">
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="30">30</option>
                    <option :value="40">40</option>
                    <option :value="50">50</option>
                  </select>
                </li>

              </ul>
            </div>
          </td>
        </tr>
        
      </tfoot>
    </table>
  </div>
</template>
  <script>
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  import Pagination from '@/pages/pagination/pagination.vue';
  export default {
      props: ['productOffersSales','loading',
              'currency','totalPages', 'page','dataperPage'],
    
      mixins: [ReusableFunctionMixins],
      components: {
        Pagination,
      },
      data() {
            return {
                recordsPerPage:10
            }
        },
        mounted:async function (){
            this.recordsPerPage = this.dataperPage
        },
        methods: {
            recordChange(){
                this.$emit('record-changed', this.recordsPerPage)
            },
            pageChange(page){
                this.$emit('page-changed', page)
            }
            
        },
  }
  </script>